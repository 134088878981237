import DatePickerCustom from "components/Table/datePicker";
import useDocumentTitle from "../../useDocumentTitle";
import TopCards from "components/Dashboard/TopCards";
import TableComponent from "components/Table/index";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Withdrawal from "components/Withdrawal";
import Deposit from "components/Deposit";
import Toast from "components/Toast";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const Dashboard = () => {
  const [jackpots, setJackpots] = useState([]);
  const [player, setPlayer] = useState([]);
  const token = document.cookie
    .split(";")
    .find((cookie) => {
      return cookie.startsWith("simba_id");
    })
    ?.split("=")[1];
  const selectedLanguage = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();
  useDocumentTitle(`${t("dashboard.title")} | ${t("site-name")}`);
  const history = useHistory();
  const notify = React.useCallback((type, message) => {
    Toast({ type, message });
  }, []);

  const lotteries = React.useMemo(
    () => [
      { value: "megamillions", text: "Mega Millions" },
      { value: "powerball", text: "Powerball" },
      { value: "euromillions", text: "Euro Millions" },
      { value: "eurojackpot", text: "Eurojackpot" },
      { value: "el-gordo-primitiva", text: "El Gordo" },
      { value: "la-primitiva", text: "La Primitiva" },
      { value: "megasena", text: "Mega-Sena" },
      { value: "superenalotto", text: "SuperEnalotto" },
      { value: "lotto-america", text: "Lotto America" },
      { value: "lotto-6aus49", text: "Lotto 6aus49" },
      { value: "sat-lotto-au", text: "Saturday Lotto" },
      { value: "powerball-au", text: "Powerball AU" },
      { value: "lotto-fr", text: "French Lotto" },
      { value: "lotto-uk", text: "UK Lottery" },
      { value: "lotto-ny-us", text: "New York Lotto" },
      { value: "thunderball", text: "Thunderball" },
      { value: "set-for-life", text: "Set For Life" },
    ],
    []
  );

  const fetchProfileData = React.useCallback(async () => {
    try {
      await fetch("api/v1/users/profile", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          return response.text().then((text) => {
            throw new Error(text);
          });
        })
        .then((data) => {
          setPlayer(data);
        })
        .catch((error) => {
          notify("error", error.message);
          if (error.message === "Unauthorized") history.push("/login");
        });
    } catch (error) {
      notify("error", error.message);
    }
  }, [history, notify, token]);

  const fetchJackpotData = React.useCallback(async () => {
    await fetch("https://api.simbalotto.com/draws", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          throw new Error(text);
        });
      })
      .then((data) => {
        const types = [
          "megamillions",
          "powerball",
          "euromillions",
          "eurojackpot",
          "el-gordo-primitiva",
          "la-primitiva",
          "megasena",
          "superenalotto",
          "lotto-america",
          "lotto-6aus49",
          "sat-lotto-au",
          "powerball-au",
          "lotto-fr",
          "lotto-uk",
          "lotto-ny-us",
          "thunderball",
          "set-for-life",
        ];

        const imagePaths = {
          eurojackpot: "eurojackpot.webp",
          euromillions: "euromillions.webp",
          superenalotto: "superenalotto.webp",
          megamillions: "megamillions.webp",
          megasena: "megasena.webp",
          powerball: "powerball.webp",
          thunderball: "thunderball.webp",
          "lotto-fr": "french-lotto.webp",
          "lotto-6aus49": "german-lotto.webp",
          "el-gordo-primitiva": "el-gordo.webp",
          "la-primitiva": "la-primitiva.webp",
          "lotto-ny-us": "lotto-ny.webp",
          "powerball-au": "powerball-au.webp",
          "sat-lotto-au": "saturday-lotto.webp",
          "lotto-america": "lotto-america.webp",
          "lotto-uk": "uk-lottery.webp",
          "set-for-life": "set-for-life-uk.webp",
        };

        const playUrls = {
          eurojackpot: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/eurojackpot`,
          euromillions: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/euromillions`,
          superenalotto: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/superenalotto`,
          megamillions: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/mega-millions`,
          megasena: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/mega-sena`,
          powerball: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/powerball`,
          thunderball: `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/thunderball`,
          "lotto-fr": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-fr`,
          "lotto-6aus49": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-6aus49`,
          "el-gordo-primitiva": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/el-gordo-primitiva`,
          "la-primitiva": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/la-primitiva`,
          "lotto-ny-us": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/new-york-lotto`,
          "powerball-au": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/powerball-au`,
          "sat-lotto-au": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/saturday-lotto-au`,
          "lotto-america": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lotto-america`,
          "lotto-uk": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/lott-uk`,
          "set-for-life": `https://onlinelotto365.com${
            selectedLanguage === "en" ? "" : `/${selectedLanguage}`
          }/play/set-for-life-uk`,
        };

        const jackpots = data.filter(
          (j) => !j.prizes && types.includes(j.type)
        );

        jackpots.forEach((jackpot) => {
          jackpot.imagePath = imagePaths[jackpot.type];
          const date = new Date(jackpot.date);
          jackpot.date = date.toDateString().split(" ").slice(0, 4).join(" ");
          jackpot.name = lotteries.find(
            (lottery) => lottery.value === jackpot.type
          )?.text;
          jackpot.playUrl = playUrls[jackpot.type];
        });
        setJackpots(jackpots);
      })
      .catch((error) => {
        notify("error", error.message);
        if (error.message === "Unauthorized") history.push("/login");
      });
  }, [history, lotteries, notify, selectedLanguage, token]);

  useEffect(() => {
    fetchProfileData();
    fetchJackpotData();
  }, [fetchJackpotData, fetchProfileData]);

  const statuses = React.useMemo(
    () => [
      { value: "pending-draw", text: "Pending Draw" },
      { value: "won", text: "Won" },
      { value: "lost", text: "Lost" },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "transaction_id",
        header: t("lotteries.fields.ticket-id"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
      },
      {
        accessorKey: "lottery",
        header: t("lotteries.fields.lottery"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: lotteries,
        Cell: ({ cell }) =>
          lotteries.find((lottery) => lottery.value === cell.getValue())?.text,
      },
      {
        accessorKey: "purchased_at",
        accessorFn: (row) => new Date(row.purchased_at),
        id: "purchased_at",
        header: t("lotteries.fields.purchase-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "draw_date",
        accessorFn: (row) => row.draw_date && new Date(row.draw_date),
        id: " draw_date",
        header: t("lotteries.fields.draw-date"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Cell: ({ cell }) =>
          cell.getValue() ? cell.getValue()?.toLocaleDateString() : "-",
        Header: ({ column }) => <em>{column.columnDef.header}</em>,
        Filter: ({ column }) => <DatePickerCustom {...column} />,
      },
      {
        accessorKey: "price",
        header: t("lotteries.fields.amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original.price.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "status",
        header: t("lotteries.fields.status"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        filterVariant: "select",
        filterSelectOptions: statuses,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <span
              className={`badge ${
                status === "won"
                  ? "badge-success"
                  : status === "pending-draw"
                  ? "badge-warning"
                  : "badge-danger"
              }`}
            >
              {statuses.find((s) => s.value === status)?.text}
            </span>
          );
        },
      },
      {
        accessorKey: "win_amount",
        header: t("lotteries.fields.win-amount"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) =>
          cell.row.original?.win_amount?.toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
          }),
      },
      {
        accessorKey: "actions",
        enableColumnFilter: false,
        enableGrouping: false,
        enableSorting: false,
        header: t("lotteries.fields.action"),
        muiTableHeadCellProps: {
          style: { textTransform: "capitalize" },
        },
        Cell: ({ cell }) => (
          <button
            className="btn btn-simbalotto-orange text-uppercase"
            onClick={() => {
              history.push(`/my-ticket/${cell.row.original.transaction_id}`);
            }}
          >
            {t("lotteries.fields.view")}
          </button>
        ),
      },
    ],
    [t, lotteries, statuses, history]
  );

  return (
    <>
      <TopCards />

      <div className="row mb-3">
        <div className="order-xl-1 mb-2 col-xl-6">
          <Deposit player={player} />
        </div>

        <div className="order-xl-2 col-xl-6">
          <Withdrawal player={player} />
        </div>
      </div>

      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="px-5"
        style={{ zIndex: 0 }}
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          576: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          994: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {jackpots &&
          jackpots.map((jackpot, index) => (
            <SwiperSlide key={index}>
              <div className="card">
                <div className="row">
                  <div className="col-8">
                    <div className="card-body">
                      <h5 className="card-title">{jackpot.name}</h5>
                      <p
                        className="card-text text-dark"
                        style={{ fontWeight: "700" }}
                      >
                        {Number(jackpot?.jackpot?.total)?.toLocaleString(
                          "de-DE",
                          {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                          }
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <img
                      src={jackpot.imagePath}
                      alt={jackpot.name}
                      style={{
                        position: "absolute",
                        maxWidth: "100px",
                      }}
                    />
                  </div>
                </div>

                <hr className="my-3" />

                <div className="row mb-2">
                  <div className="col-md-8 col-6">
                    <p className="mx-2">{jackpot.date}</p>
                  </div>
                  <div className="col-md-4 col-6 d-flex justify-content-end">
                    <a
                      className="btn w-100 btn-simbalotto-orange text-uppercase mx-2"
                      href={jackpot.playUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("menu.play")}
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="row mt-3">
        <div className="col-xl-12 mb-5 mb-xl-0" style={{ zIndex: 0 }}>
          <TableComponent
            url="/api/v1/userLotteries/all?"
            columns={columns}
            title={t("lotteries.my-lotteries")}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
